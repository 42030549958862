import { storeToRefs } from 'pinia'
import { useUserApi } from '~/api/user'
import { useAppStore } from '~/store/app'
import { PAGE } from '~/constants/router'
import { useAccountStore } from '~/store/account'

export const useLogout = () => {
  const { LOG_OUT } = useUserApi()
  const { $axios, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { updateTransactionDetail, updateListUserBank } = useAccountStore()
  const { refreshInterval } = storeToRefs(store)
  const { setCurrentUser, setRefreshInterval } = store
  const logout = async () => {
    try {
      const { data } = await $axios.post(LOG_OUT)
      if (data && data.status === 'OK') {
        setCurrentUser(null)
        updateTransactionDetail(null)
        updateListUserBank([])
        if (refreshInterval) {
          clearInterval(refreshInterval as any)
          setRefreshInterval(null)
        }
        // CLear local storage
        for (let i = 0; i < localStorage.length; i++) {
          const _key: string | null | any = localStorage.key(i)
          if (['app', 'account'].includes(_key)) {
            localStorage.removeItem(_key)
          }
        }
        localStorage.clear()
        navigateTo(PAGE.HOME)
      }
    } catch (error) {}
  }

  return {
    logout
  }
}
