<template>
  <BaseModal :config="config">
    <template #content>
      <FormForgotPassword v-if="tabActive === MODAL_TYPES.FORGOT_PASSWORD" />
      <div
        v-else
        class="modal-login"
      >
        <div class="logo-container">
          <BaseImg
            :src="LOGO_SITE_IMAGE"
            alt="logo"
          />
          <div class="modal-close" @click="onHideModal()">
            <span class="icon-close-circle-fill" />
          </div>
        </div>
        <div
          class="tab-header"
          :class="{ 'bg-register': tabActive === MODAL_TYPES.REGISTER }"
        >
          <div
            class="tab-header-item"
            :class="{ active: tabActive === MODAL_TYPES.LOGIN }"
            @click="onChangeTab(MODAL_TYPES.LOGIN)"
          >
            {{ FORM_AUTH.LOGIN }}
          </div>
          <div
            class="tab-header-item"
            :class="{ active: tabActive === MODAL_TYPES.REGISTER }"
            @click="onChangeTab(MODAL_TYPES.REGISTER)"
          >
            {{ FORM_AUTH.REGISTER }}
          </div>
        </div>
        <div class="tab-content">
          <FormLogin
            v-if="tabActive === MODAL_TYPES.LOGIN"
            :is-show-modal="isShowModal"
            :tab-active="tabActive"
          />
          <FormRegister
            v-if="tabActive === MODAL_TYPES.REGISTER"
            :is-show-modal="isShowModal"
            :tab-active="tabActive"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LocationQueryValue } from 'vue-router'
import BaseModal from '~/components/common/base-modal.vue'
import FormLogin from '~/components/common/form/login'
import FormRegister from '~/components/common/form/register.vue'
import FormForgotPassword from '~/components/common/form/forgot-password'
import { MODAL_TYPES } from '~/config/constant'
import { useAppStore } from '~/store/app'
import { FORM_AUTH } from '~/constants/form/auth'
import useModal from '~/composables/useModal'
import { ModalConfig } from '~/types/modal.type'
import { LOGO_SITE_IMAGE } from '~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'

const route = useRoute()
const { closeModal, openModal } = useModal()
const tabActive = ref<string | LocationQueryValue[]>(MODAL_TYPES.LOGIN)
const { $pinia, $device } = useNuxtApp()
const config: ModalConfig = {
  id: MODAL_TYPES.LOGIN,
  centered: $device.isMobileOrTablet,
  hideClose: true,
  fullscreen: false,
  iconClose: 'icon-close-circle-fill',
  textBack: 'Back',
  noCloseOnBackdrop: true
}
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const isShowModal = ref(false)

watch(route, () => {
  if (route.query.popup) {
    tabActive.value = route.query.popup
  } else {
    closeModal(MODAL_TYPES.LOGIN)
  }
})

onMounted(() => {
  if (
    (route.query.popup === MODAL_TYPES.LOGIN ||
          route.query.popup === MODAL_TYPES.REGISTER ||
          route.query.popup === MODAL_TYPES.FORGOT_PASSWORD) &&
      !isLogged.value
  ) {
    tabActive.value = route.query.popup
    nextTick(() => {
      setTimeout(() => {
        openModal(MODAL_TYPES.LOGIN)
      }, 200)
    })
  }
})

const onChangeTab = (tabName: string) => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: tabName }
  })
}

const onHideModal = () => {
  isShowModal.value = false

  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: undefined, openUrl: undefined, navigateUrl: undefined }
  })
}
const onShowModal = () => {
  isShowModal.value = true
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/modals/login/index.scss" />
